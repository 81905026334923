import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import { BenchmarkChart } from "./chart/chart";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`As a result of the pandemic, like all schools, PSI experienced an atypical year with unique challenges and opportunities.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8b9a3c4b9900d14afb8e14efaeda7782/97a96/04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAADEUlEQVQ4y02UXWsdVRSGc23/gBeC4E/wyuKlUvA/qKUi0utCQvVCLAS8UyoURGrRqkixgkU0qLW1pUnUGpLUxjS1Sc6ZZs6Z74+z98ze87Uf2TNt48BmzSxY717rfd9ZcxNZMC1KbDwQklDr/tv9X163LY+fzkDbGlr7ApghZzpjMMaYub5YFH1hbjruuB5OLoiUxpWyz1ddx3QasbU9IoxniKIim5WMHZ+Jl9iciRJB1xkzZwvCqiI1LRcuXefF42f44M4KO1lCVGpcIamNYTxyWV3dYG1tizyX1E1LECbEcYYslClLPXTolYp7rsfC4kWOvjTPy28scurPJc6sX2c5nOCXuu/QD1IcNyITikI15EITpZIkKwgTYbKZGgCjuua1ha956tkTnFz4mI39CTf8h8yvLvHKl2f5YfvvnqvAjxiPDgj9CNO1VFXF5MAjz3IKWZiyKAdAScO7529z5IVzfHPjPpb+tKw4/ulZnjt1gtvOqCdeNy25bkgKzaxqmOmGaTwjyCSxUCYtNJ0FTNuK3+66PHPsAm8uXsWpO67u7vP8Oyd567NzhKrqR3acKcurm2xt7yEKhaob3GlEGGckmTCqqnvRe5XtOfr6JZ5+b5VXt2qOrWQcefs0H/38HdKAahvcA4/7O/t405DQj6l0zcQN8CYBWTozWlXDyNZvsSr5fGXE/GbOh3sF7+/WnF5z2Jy4BEr3PpRlSZTlZFJSVBqhFKkQ5IW0OSPKchjZ2ibQFUlTExWSQEpCKUi1xleHtnHGE/5a2+Lezh5hmJDNBKOxy9QL8YPYKK0fjVyWbOw6/OtHxF1HUNVETYOnVA/22Nh5LoiTjJmQJGmOBbA+9LzIRiOLcgC0Bd8u3eTyjze5tb7NrfV/uPbHJnfHLr4eOrSAUZyy67gUVUVtOqTWTIKIIEnxosRYCp4AXvllmS8u/8QnF69w/qvv+fX3DUZJ9uSftoBWiAcPHA4eemTZjLoeRImi1FJghCgOVbbFe2HCKB6ivcTyZ6M9qjlcDmZYAsNS6DratqPtOmOjVfk/TwL/OHcoFZYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "04",
            "title": "04",
            "src": "/static/8b9a3c4b9900d14afb8e14efaeda7782/c1b63/04.png",
            "srcSet": ["/static/8b9a3c4b9900d14afb8e14efaeda7782/5a46d/04.png 300w", "/static/8b9a3c4b9900d14afb8e14efaeda7782/0a47e/04.png 600w", "/static/8b9a3c4b9900d14afb8e14efaeda7782/c1b63/04.png 1200w", "/static/8b9a3c4b9900d14afb8e14efaeda7782/d61c2/04.png 1800w", "/static/8b9a3c4b9900d14afb8e14efaeda7782/97a96/04.png 2400w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The significant ambiguity and necessity for change required adaptability in order to maintain the highest possible quality learning as PSI responded to health and safety precautions.  That said, we believe in the importance of reporting out data to the community regarding our progress and the challenges associated with our growth as a school. The data for 2020 - 21 is indeed indicative of a turbulent year, during which,  in some cases, data was difficult to obtain. We are continuing to analyse and collect additional data to help us better understand if there are any trends or if results are an anomaly. In this report, we provide a qualitative overview of our progress, whilst still providing a full quantitative report of all the strategic plan goals’ measures, which can be found `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/18QJGKwplt7YON3v79uGBK7Oscm1X95JL/view"
      }}>{`here`}</a>{`.`}</p>
    <p>{`PSI’s Leading School Benchmarks were adopted in November, 2020 as part of the Board of Governors desire to measure what we value, as an assurance that we are continuing to provide a high-quality learning experience at PSI, in line with our Mission.`}</p>
    <p>{`One key learning this year is that we are trying to collect data on an overly large number of measures.  Additionally, we recognise that many of these measures derive from the climate survey which is cumbersome in length, causing survey fatigue or non-submission by many stakeholders.  We plan to analyse the effectiveness of all measures and consider removing those which are less impactful, so that we will have a more manageable amount of data to collect, analyse and report on in the future.`}</p>
    <BenchmarkChart mdxType="BenchmarkChart" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      